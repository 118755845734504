import React, {useEffect, useState} from "react"
import './Root.scss'
import {Outlet} from "react-router-dom"
import Header from "./components/header/Header"
import TopNav from "./components/header/top-nav/TopNav"

const STICKY_HEADER_THRESHOLD = 40

export default function Root() {
    const [pagerClassNames, setPageClassNames] = useState('page');
    const [scrolled, setScrolled] = useState(false);
    const [mobile, setMobile] = useState(true); // FIXME disabled for now (should be false by default)
    const [sticky, setSticky] = useState(true); // FIXME disabled for now (should be false by default)
    const [showTopNav, setShowTopNav] = useState(false); // FIXME disabled for now (should be true by default)
    let topNav: JSX.Element | undefined = <TopNav />

    // FIXME disabled for now (refactor to use useMediaQuery)
    // useEffect(() => {
    //     const handleMediaQueryChange = (event: any) => {
    //         setMobile(event.matches)
    //     }
    //     const handleScroll = () => {
    //         setScrolled(window.scrollY >= STICKY_HEADER_THRESHOLD)
    //     };

    //     const mediaQuery = window.matchMedia('(max-width: 1010px)')

    //     mediaQuery.addEventListener('change', handleMediaQueryChange)
    //     window.addEventListener('scroll', handleScroll)

    //     // init
    //     setMobile(mediaQuery.matches)
    //     // setShowTopNav(!mediaQuery.matches) // FIXME disabled for now

    //     return () => {
    //         mediaQuery.removeEventListener('change', handleMediaQueryChange)
    //         window.removeEventListener('scroll', handleScroll)
    //     };
    // }, [])

    useEffect(() => {
        const shouldBeSticky = mobile // || scrolled
        setSticky(shouldBeSticky)
        setPageClassNames(['page', shouldBeSticky ? 'sticky-header' : ''].join(' '))
        setShowTopNav(!shouldBeSticky)
    }, [/*scrolled,*/ mobile])

    return (
        <div className={pagerClassNames}>
            {showTopNav ? <TopNav /> : ''}
            <Header isSticky={sticky}></Header>
            <Outlet />
            <footer className="footer"></footer>
        </div>
    )
}