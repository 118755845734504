import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { Market } from '../entities/Market'
import { ProductsListViewLoader } from '../components/loaders/ProductsListViewLoader'
import useApi from '../api/request'
import endpoints from '../api/endpoints'

const MarketsContext = createContext<Market[]>([])

interface MarketsProviderProps {
  children?: ReactNode
}
export function MarketsProvider({ children }: MarketsProviderProps) {
  const [markets, setMarkets] = useState<Market[]>([]);

  const [getMarkets, { loading }] = useApi(endpoints.market_getAll, {
    onCompleted: (data: Market[]) => {
      setMarkets(data)
    }
  })

  useEffect(() => {
    getMarkets()
  }, [])

  if (loading) {
    // fixme use appropriate loader
    return (
      <ProductsListViewLoader aside={false} />
    )
  }

  return (
    <MarketsContext.Provider value={markets}>
      {children}
    </MarketsContext.Provider>
  )
}

export const useMarkets = () => useContext(MarketsContext)

export const getMarketById = (id: number, markets: Market[]): Market => {
  return (markets.find(market => market.id == id) as Market)
}
export const getMarketByName = (name: string, markets: Market[]): Market => {
  return (markets.find(market => market.name == name) as Market)
}
