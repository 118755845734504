// base
// TODO make it with env var
export const BASE_URL = 'https://napromotsia.bg'

// search
export const SEARCH_ROUTE = '/search'

// categories
export const CATEGORY_ROUTE_PLACEHOLDER = 'categoryUrlName'
export const CATEGORY_ROUTE = '/c/:' + CATEGORY_ROUTE_PLACEHOLDER
export const CATEGORY_FOOD_ROUTE = '/c/хранителни-продукти-151'
export const CATEGORY_HOUSEHOLD_GOODS_ROUTE = '/c/домашни-потреби-12'
export const CATEGORY_DRUGSTORE_ROUTE = '/c/козметика-и-дрогерия-11'

// base products
export const BASE_PRODUCT_ROUTE_PLACEHOLDER = 'baseProductUrlName'
export const BASE_PRODUCT_ROUTE = '/bp/:' + BASE_PRODUCT_ROUTE_PLACEHOLDER
export const BASE_PRODUCT_ROUTE_PATTERN = (urlName: string): string => '/bp/' + urlName

// shopping list
export const SHOPPING_LIST_ROUTE = '/shopping-list'
export const SAVED_SHOPPING_LISTS_ROUTE = '/saved-lists'
export const SHOPPING_LIST_UUID_ROUTE_PLACEHOLDER = 'shoppingListUuid'
export const SAVED_SHOPPING_LIST_ROUTE = '/shop/:' + SHOPPING_LIST_UUID_ROUTE_PLACEHOLDER
export const getLinkToSavedShoppingList = (uuid: string, external: boolean) => {
    return (external ? BASE_URL : '') + '/shop/' + uuid
}