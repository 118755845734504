import React from "react"
import './Header.scss'
import MenuIcon from '@mui/icons-material/Menu'
import {Link, useLocation, useNavigate} from "react-router-dom"
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import {
    CATEGORY_DRUGSTORE_ROUTE,
    CATEGORY_FOOD_ROUTE,
    CATEGORY_HOUSEHOLD_GOODS_ROUTE,
    SEARCH_ROUTE,
    SHOPPING_LIST_ROUTE
} from "../../routing/routes"
import ShoppingListIndicator from "./ShoppingListIndicator"
import MobileMenu from "./MobileMenu"
import { useAppDispatch } from "../../redux/hooks"
import { setTerm } from "../../redux/searchSlice"

interface HeaderProps {
    isSticky: boolean
}

export default function Header(props: HeaderProps) {
    const dispatch = useAppDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const urlPath = decodeURIComponent(location.pathname)
    const isFood = urlPath.startsWith(CATEGORY_FOOD_ROUTE)
    const isHouseholdGoods = urlPath.startsWith(CATEGORY_HOUSEHOLD_GOODS_ROUTE)
    const isDrugstore = urlPath.startsWith(CATEGORY_DRUGSTORE_ROUTE)

    const [sideNavigationOpen, setSideNavigationOpen] = React.useState(false)
    const toggleSideMenu = (open: boolean) => (e: React.KeyboardEvent | React.MouseEvent) => {
        if (
            e.type === 'keydown' &&
            ((e as React.KeyboardEvent).key === 'Tab' || (e as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setSideNavigationOpen(open);
    }
    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newTerm = e.target.value
        dispatch(setTerm(newTerm))

        if (newTerm.length > 2 && location.pathname !== SEARCH_ROUTE) {
            navigate(SEARCH_ROUTE)
        }
    }

    return (
        <header className={['header', props.isSticky ? 'sticky' : 'full'].join(' ')}>
            <div className="main-header">
                <MenuIcon className="mobile-menu" onClick={toggleSideMenu(true)}></MenuIcon>
                <div className="logo">
                    <Link to="/">На промоция</Link>
                </div>
                {isFood ? <Link className="selected-category-nav" to={CATEGORY_FOOD_ROUTE}>Хранителни продукти</Link> : null}
                {isHouseholdGoods ? <Link className="selected-category-nav" to={CATEGORY_HOUSEHOLD_GOODS_ROUTE}>Домашни потреби</Link> : null}
                {isDrugstore ? <Link className="selected-category-nav" to={CATEGORY_DRUGSTORE_ROUTE}>Дрогерия</Link> : null}
                <div className="search">
                    <SearchRoundedIcon className="magnifier-icon" />
                    <input type="text" placeholder="Търсене..." onChange={onSearch}/>
                </div>                
                <Link to={SHOPPING_LIST_ROUTE} className="shopping-list-link">
                    <ShoppingListIndicator />
                </Link>
            </div>
            <nav className="categories-nav">
                <div>
                    <div className={['category-link', isFood ? 'selected' : ''].join(' ')}>
                        <Link to={CATEGORY_FOOD_ROUTE}>Хранителни продукти</Link>
                    </div>
                    <div className={['category-link', isHouseholdGoods ? 'selected' : ''].join(' ')}>
                        <Link to={CATEGORY_HOUSEHOLD_GOODS_ROUTE}>Домашни потреби</Link>
                    </div>
                    <div className={['category-link', isDrugstore ? 'selected' : ''].join(' ')}>
                        <Link to={CATEGORY_DRUGSTORE_ROUTE}>Козметика и дрогерия</Link>
                    </div>
                </div>
                <div className="ad">
                    Реклама
                </div>
            </nav>
            <MobileMenu
                isOpen={sideNavigationOpen}
                toggleMenu={toggleSideMenu}
            />
        </header>
    )
}