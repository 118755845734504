import {combineReducers, configureStore} from '@reduxjs/toolkit'
import authReducer from './authSlice'
import configReducer from './configSlice'
import shoppingListReducer from './shoppingListSlice'
import savedShoppingListReducer from './savedShoppingListSlice'
import searchReducer from './searchSlice'
import {persistReducer, persistStore} from "redux-persist"
import localStorage from 'redux-persist/lib/storage'
// import sessionStorage from 'redux-persist/lib/storage/session'
import {FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE} from "redux-persist/es/constants"


const persistConfig = {
  key: 'napromotsia',
  storage: localStorage,
  // synchronize just these slices with local storage (don't synchronize 'search' slice):
  whitelist: ['shoppingList', 'config', 'auth', 'savedShoppingList']
}

const rootReducer = combineReducers({
    auth: authReducer,
    config: configReducer,
    shoppingList: shoppingListReducer,
    savedShoppingList: savedShoppingListReducer,
    search: searchReducer
})
const persistedReducer = persistReducer(persistConfig, rootReducer)
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
      // suppress error messages 'A non-serializable value was detected in an action, in the path: `register`.'
      // officially suggested in redux docs: https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
})

// used by redux-persist for storing the redux state (in my case into the local storage)
export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type AppSubscribe = typeof store.subscribe
export type RootState = ReturnType<typeof store.getState>
