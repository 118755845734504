import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'

export interface SearchEntityProduct {
  id: number
  name: string
  baseProduct: {
    name: string
  }
  brand: {
    name: string
  }
}

export interface SearchEntityBaseProduct {
  id: number
  name: string
  urlName: string
}

export interface SearchEntityBrand {
  id: number
  name: string
}

export interface SearchState {
  term: string
}

const initialState: SearchState = {
  term: ''
}

export const searchReducer = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setTerm: (state, action: PayloadAction<string>) => {
      state.term = action.payload
    }
  }
})

export const { setTerm } = searchReducer.actions

export const getTerm = (state: RootState): string => state.search.term

export default searchReducer.reducer
